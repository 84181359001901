import { useQuery } from "@tanstack/react-query";
import { Customer, ProgramType, Source } from "api/client.generated";
import { PriceRuleModelStringConstants } from "types/price-rule-model";
import useApi from "./useApi";

interface UseSourceCustomersReturn {
  customers: Customer[] | undefined;
  isCustomersFetching: boolean;
}

const useSourceCustomers = (source: Source, program: ProgramType): UseSourceCustomersReturn => {
  const { customersClient } = useApi();

  const { data: customers, isFetching: isCustomersFetching } = useQuery({
    queryKey: ["getSourceCustomers", source, program],
    queryFn: () => customersClient.getSourceCustomers(source, program),
    enabled:
      `${source}` !== PriceRuleModelStringConstants.ALL_SOURCES &&
      `${program}` !== PriceRuleModelStringConstants.ALL_PROGRAMS,
  });

  return { customers, isCustomersFetching };
};

export default useSourceCustomers;
