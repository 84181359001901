import { useQuery } from "@tanstack/react-query";
import { ApiPermissionOptions } from "api/client.generated";
import useApi from "./useApi";

interface UsePermissionsReturn {
  permissions: ApiPermissionOptions[] | undefined;
  isPermissionsFetching: boolean;
}
const usePermissions = (isQueryEnable: boolean): UsePermissionsReturn => {
  const { permissionsClient } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["getPermissions"],
    queryFn: () => permissionsClient.getPermissions() as Promise<ApiPermissionOptions[]>,
    enabled: isQueryEnable,
  });

  return { permissions: data, isPermissionsFetching: isFetching };
};

export default usePermissions;
