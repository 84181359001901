import { Box, Paper, Stack, TextField } from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateProductMappingTargetId } from "api/client.generated";
import { ValidationException } from "api/client.generated.extensions";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormTextField from "components/primitives/FormTextField";
import { useNotificationContext } from "context/notificationContext";
import useApi from "hooks/api/useApi";
import { EditProductMappingNavState } from "pages/Sync/Product/EditProductMappingPage";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import NotificationMessage from "types/notification-message";
import SyncRouteParams from "types/sync-route-params";
import * as yup from "yup";

const ProductMappingForm = () => {
  const { source, target, program } = useParams<keyof SyncRouteParams>() as SyncRouteParams;
  const { state } = useLocation();
  const { productsClient } = useApi();
  const { mapping } = state as EditProductMappingNavState;

  const { raiseSuccessNotification, raiseErrorNotification } = useNotificationContext();
  const [isSaving, setIsSaving] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");

  const formSchema = yup.object().shape({
    targetId: yup
      .number()
      // this is to prioritize 'Required' vs 'Type error'
      .transform((value, origin) => (origin.toString() === "" ? undefined : value))
      .typeError("Invalid ID")
      .min(0, "Invalid ID")
      .max(0x7fffffff, "Invalid ID") // max signed 32bit int
      .required("Required"),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const { control, handleSubmit } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
    defaultValues: { targetId: mapping.targetId },
  });

  const productSyncRoute = useMemo(
    () => AppRoutes.productSync.buildRoute(source, target, program),
    [source, target, program],
  );

  const navigate = useNavigate();

  const onSave = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);

    try {
      await productsClient.updateProductMapping(target, mapping.id, source, program, {
        targetId: formData.targetId,
        targetType: mapping.targetType,
      } as UpdateProductMappingTargetId);
      raiseSuccessNotification(NotificationMessage.PRODUCT_MAPPING_UPDATED);
      navigate(productSyncRoute);
    } catch (e: unknown) {
      const validationError = ValidationException.parse(e);
      setFormErrorMsg(
        validationError
          ? validationError.getFirstErrorMessage()
          : NotificationMessage.UNKNOWN_ERROR,
      );
      raiseErrorNotification(NotificationMessage.FAILED_TO_UPDATE_PRODUCT_MAPPING);
    }
    setIsSaving(false);
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2} maxWidth="500px">
        <Stack spacing={4}>
          <TextField
            label="Source Product"
            testId="Source Product"
            defaultValue={mapping.sourceName}
            InputProps={{ readOnly: true }}
            disabled={isSaving}
          />
          <TextField
            label="Target Product"
            testId="Target Product"
            defaultValue={mapping.targetName}
            InputProps={{ readOnly: true }}
            disabled={isSaving}
          />
          <FormTextField
            control={control}
            bindSchemaFieldName="targetId"
            label={`${mapping.targetType} ID`}
            testId={`${mapping.targetType} ID`}
            disabled={isSaving}
            required
          />
          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons cancelRoute={productSyncRoute} isSaveLoading={isSaving} />
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default ProductMappingForm;
