import { useQuery } from "@tanstack/react-query";
import { CustomerMappingModel, ProgramType, Source, Target } from "api/client.generated";
import useApi from "./useApi";

const useCustomerMapping = (
  id: string,
  source: Source,
  target: Target,
  program: ProgramType,
): {
  customerMapping: CustomerMappingModel | undefined;
  isCustomerMappingFetching: boolean;
} => {
  const { customerMappingsClient } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["getCustomerMapping", id, source, target, program],
    queryFn: () => customerMappingsClient.getCustomerMapping(id, source, target, program),
  });

  return { customerMapping: data, isCustomerMappingFetching: isFetching };
};

export default useCustomerMapping;
