import { Stack } from "@crayon/design-system-react";
import { Customer, ProgramType, Source, Target } from "api/client.generated";
import BillingMappingForm from "components/hoc/Sync/Billing/BillingMappingForm";
import TextHeader from "components/primitives/TextHeader";
import EditCustomerMappingViewModel from "types/edit-customer-mapping-vm";

interface BillingMappingAddEditLayoutProps {
  source: Source;
  target: Target;
  program: ProgramType;
  mapping: EditCustomerMappingViewModel | undefined;
  isMappingLoading?: boolean;
  targetCustomers: Customer[] | undefined;
  isTargetCustomersLoading?: boolean;
}

const BillingMappingAddEditLayout = ({
  source,
  target,
  program,
  mapping,
  isMappingLoading,
  targetCustomers,
  isTargetCustomersLoading,
}: BillingMappingAddEditLayoutProps) => (
  <Stack spacing={2}>
    <TextHeader
      testId="page-header"
      label={`Billing Sync | ${source} ${">"} ${target} ${">"} ${program}`}
    />
    <BillingMappingForm
      mapping={mapping}
      isMappingLoading={isMappingLoading}
      targetCustomers={targetCustomers}
      isTargetCustomersLoading={isTargetCustomersLoading}
    />
  </Stack>
);

export default BillingMappingAddEditLayout;
