import { Paper, Table, TableBody, TableContainer } from "@crayon/design-system-react";
import { SubscriptionSyncResult } from "api/client.generated";
import UsageSyncDetailsLayout from "components/layout/Sync/UsageSyncDetailsLayout";
import SyncOutcomeIcon from "components/primitives/SyncOutcomeIcon";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodySyncOutcomeCell from "components/primitives/TableBodySyncOutcomeCell";
import TableRowCollapsible from "components/primitives/TableRowCollapsible";

interface SubscriptionsSyncDetailsTableProps {
  subscriptionSyncResults: SubscriptionSyncResult[] | undefined;
}

const SubscriptionsSyncDetailsTable = (props: SubscriptionsSyncDetailsTableProps) => {
  const { subscriptionSyncResults } = props;

  const getIcon = (syncResult: SubscriptionSyncResult) => {
    const isWarning = syncResult.usageRecordSyncResults?.some((x) => Boolean(x.warnings?.length));
    return <SyncOutcomeIcon syncOutcome={syncResult.outcome} isAnyWarnings={isWarning} />;
  };

  return (
    <TableContainer component={Paper}>
      <Table testId="Subscriptions Table">
        <TableBody>
          {subscriptionSyncResults?.map((syncResult, i) => (
            <TableRowCollapsible
              key={(syncResult.sourceSubscription?.id ?? "") + String(i)}
              columnCount={2}
              oneRow={false}
              collapsibleContent={
                <UsageSyncDetailsLayout usageSyncResults={syncResult.usageRecordSyncResults} />
              }
            >
              {syncResult.sourceSubscription?.productName ? (
                <TableBodyCell
                  label={syncResult.sourceSubscription?.productName}
                  icon={getIcon(syncResult)}
                />
              ) : (
                <TableBodyCell
                  label={`Sku: ${syncResult.sourceSubscription?.productSku}`}
                  fontItalic
                  icon={getIcon(syncResult)}
                />
              )}
              <TableBodySyncOutcomeCell syncOutcome={syncResult.outcome} align="right" />
            </TableRowCollapsible>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubscriptionsSyncDetailsTable;
