import BillingMappingAddEditLayout from "components/layout/Sync/BillingMappingAddEditLayout";
import useCustomerMapping from "hooks/api/useCustomerMapping";
import useTargetCustomerOptionsByMapping from "hooks/api/useTargetCustomerOptionsForMapping";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditCustomerMappingViewModel, {
  MappingToEditMappingVm,
} from "types/edit-customer-mapping-vm";
import SyncRouteParams from "types/sync-route-params";

interface EditMappingRouteParams extends SyncRouteParams {
  id: string;
}

const BillingMappingEditPage = () => {
  const { source, target, program, id } = useParams<
    keyof EditMappingRouteParams
  >() as EditMappingRouteParams;

  const [mappingVm, setMappingVm] = useState<EditCustomerMappingViewModel | undefined>(undefined);

  const { customerMapping, isCustomerMappingFetching } = useCustomerMapping(
    id,
    source,
    target,
    program,
  );

  const { targetCustomerOptions, isTargetCustomerOptionsFetching } =
    useTargetCustomerOptionsByMapping(id, source, target, program);

  useEffect(() => {
    if (customerMapping) setMappingVm(MappingToEditMappingVm(customerMapping));
  }, [customerMapping]);

  return (
    <BillingMappingAddEditLayout
      source={source}
      target={target}
      program={program}
      mapping={mappingVm}
      isMappingLoading={isCustomerMappingFetching}
      targetCustomers={targetCustomerOptions}
      isTargetCustomersLoading={isTargetCustomerOptionsFetching}
    />
  );
};

export default BillingMappingEditPage;
