import { useQuery } from "@tanstack/react-query";
import { Customer, ProgramType, Source } from "api/client.generated";
import useApi from "./useApi";

interface UseSourceCustomerAgreementReturn {
  customer: Customer | undefined;
  isCustomerFetching: boolean;
}

const useSourceCustomerAgreement = (
  source: Source,
  program: ProgramType,
  customerId: string,
  agreementId: string,
): UseSourceCustomerAgreementReturn => {
  const { customersClient } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["getSourceCustomerAgreement", source, program, customerId, agreementId],
    queryFn: () =>
      customersClient.getSourceCustomerAgreement(source, program, customerId, agreementId),
  });

  return { customer: data, isCustomerFetching: isFetching };
};

export default useSourceCustomerAgreement;
