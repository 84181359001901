import { useQuery } from "@tanstack/react-query";
import { Customer, ProgramType, Source, Target } from "api/client.generated";
import useApi from "./useApi";

interface UseTargetCustomerOptionsByMappingReturn {
  targetCustomerOptions: Customer[] | undefined;
  isTargetCustomerOptionsFetching: boolean;
}

const useTargetCustomerOptionsByMapping = (
  id: string,
  source: Source,
  target: Target,
  program: ProgramType,
): UseTargetCustomerOptionsByMappingReturn => {
  const { customerMappingsClient } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["getTargetCustomerOptionsByMapping", id, source, target, program],
    queryFn: () =>
      customerMappingsClient.getTargetCustomerOptionsByMapping(id, source, target, program),
  });

  return { targetCustomerOptions: data, isTargetCustomerOptionsFetching: isFetching };
};

export default useTargetCustomerOptionsByMapping;
