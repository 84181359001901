import { ProgramType } from "api/client.generated";

const getProgramTypeDisplayName = (program: ProgramType): string => {
  switch (program) {
    case ProgramType.MicrosoftCsp:
      return "Microsoft CSP";
    default:
      return program;
  }
};

export default getProgramTypeDisplayName;
