import {
  BillingMotion,
  Customer,
  CustomerMappingModel,
  PricingSyncStrategy,
  ProgramType,
} from "api/client.generated";

interface CustomerViewModel {
  id: string;
  name: string;
}

interface EditCustomerMappingViewModel {
  id: string | undefined;
  programType: ProgramType;
  sourceCustomer: CustomerViewModel;
  sourceCustomerAgreement: CustomerViewModel;
  targetCustomer: CustomerViewModel | undefined;
  targetCustomerAgreement: CustomerViewModel | undefined;
  isSyncing: boolean;
  pricingSyncStrategy: PricingSyncStrategy;
  billingMotion: BillingMotion;
}

export const MappingToEditMappingVm = (cm: CustomerMappingModel): EditCustomerMappingViewModel => {
  const model: EditCustomerMappingViewModel = {
    id: cm.id,
    billingMotion: cm.billingMotion,
    isSyncing: cm.isSyncing,
    pricingSyncStrategy: cm.pricingSyncStrategy,
    programType: cm.programType,
    sourceCustomer: {
      id: cm.sourceCustomer?.id ?? "",
      name: cm.sourceCustomer?.name ?? "",
    },
    sourceCustomerAgreement: {
      id: cm.sourceCustomer?.agreement?.id ?? "",
      name: cm.sourceCustomer?.agreement?.name ?? "",
    },
    targetCustomer:
      !!cm.targetCustomer?.id && cm.targetCustomer?.name
        ? {
            id: cm.targetCustomer?.id ?? "",
            name: cm.targetCustomer?.name ?? "",
          }
        : undefined,
    targetCustomerAgreement:
      !!cm.targetCustomer?.agreement?.id && !!cm.targetCustomer?.agreement?.name
        ? {
            id: cm.targetCustomer?.agreement?.id ?? "",
            name: cm.targetCustomer?.agreement?.name ?? "",
          }
        : undefined,
  };

  return model;
};

export const CreateNewEditMappingVm = (
  srcCustomer: Customer,
  billingMotion: BillingMotion,
  program: ProgramType,
): EditCustomerMappingViewModel => {
  const model: EditCustomerMappingViewModel = {
    id: undefined,
    billingMotion,
    isSyncing: false,
    pricingSyncStrategy: PricingSyncStrategy.CostAndRetailPricing,
    programType: program,
    sourceCustomer: { id: srcCustomer.id, name: srcCustomer.name ?? "" },
    sourceCustomerAgreement: {
      id: srcCustomer.agreements?.at(0)?.id ?? "",
      name: srcCustomer.agreements?.at(0)?.name ?? "",
    },
    targetCustomer: undefined,
    targetCustomerAgreement: undefined,
  };

  return model;
};

export default EditCustomerMappingViewModel;
