import {
  Box,
  Chip,
  Grid,
  IconButton,
  LinkBehavior,
  ListItemIcon,
  Menu,
  MenuItem,
  NormalButton,
  Paper,
  PrimaryButton,
  SuccessChip,
  WarningChip,
} from "@crayon/design-system-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ConnectionState, Source, Target } from "api/client.generated";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useScreen from "hooks/useScreen";
import { AddTaskIcon, BookmarkIcon, EditIcon } from "images/MuiIcons";
import { BaseSyntheticEvent, useMemo, useState } from "react";
import AppRoutes from "routes/app-routes";
import { Endpoint, EndpointName } from "types/Endpoint";
import isValidHttpUrl from "utils/url-validators";

interface IConnectionCard {
  endpointName: EndpointName;
  status: ConnectionState | undefined;
}

const ConnectionCard = ({ endpointName, status }: IConnectionCard) => {
  const { smMatch, mdMatch } = useScreen();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { partnerConfig } = usePartnerConfigContext();

  const lineBreak = "%0D%0A";
  const requestEmailSubject: string = "Onboarding request";
  const requestEmailBody: string =
    `Hi Support,${lineBreak}${lineBreak}` +
    `Requesting to be onboarded to Billing Sync.${lineBreak}${lineBreak}` +
    `Partner Name: ${partnerConfig?.partnerName}${lineBreak}` +
    `Partner Id: ${partnerConfig?.partnerId}${lineBreak}` +
    `Requested Source: ${Endpoint.isSource(endpointName) ? endpointName : ""}${lineBreak}` +
    `Requested PSA: ${Endpoint.isSource(endpointName) ? "" : endpointName}${lineBreak}${lineBreak}` +
    `Thanks`;
  const requestEmailHref = `mailto:${process.env.REACT_APP_CRAYON_SUPPORT_EMAIL}?subject=${requestEmailSubject}&body=${requestEmailBody}`;

  const kbDocUrl = useMemo<string | undefined>(() => {
    let url: string | undefined;

    switch (endpointName) {
      case Source.Prism:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_PRISM_URL;
        break;
      case Source.CloudIq:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_CLOUDIQ_URL;
        break;
      case Target.Autotask:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_AUTOTASK_URL;
        break;
      case Target.ConnectWise:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_CONNECTWISE_URL;
        break;
      case Target.Syncro:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_SYNCRO_URL;
        break;
      default:
        url = undefined;
    }

    return url === undefined || !isValidHttpUrl(url) ? undefined : url;
  }, [endpointName]);

  const ActionButtons = (
    <>
      {kbDocUrl && (
        <NormalButton startIcon={<BookmarkIcon />} onClick={() => window.open(kbDocUrl)}>
          Documentation
        </NormalButton>
      )}
      <Box display="flex" justifyContent="center" minWidth="125px">
        {status === ConnectionState.RequestAccess && (
          <PrimaryButton
            component={LinkBehavior}
            href={requestEmailHref}
            variant="contained"
            startIcon={<AddTaskIcon />}
          >
            Request
          </PrimaryButton>
        )}
        {status !== ConnectionState.RequestAccess && (
          <PrimaryButton
            component={LinkBehavior}
            href={AppRoutes.connectionEdit.buildRoute(endpointName)}
            startIcon={<EditIcon />}
          >
            Edit
          </PrimaryButton>
        )}
      </Box>
    </>
  );

  const ActionMenu = (
    <>
      <IconButton onClick={(e: BaseSyntheticEvent) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        onClose={() => setAnchorEl(null)}
      >
        {kbDocUrl && (
          <MenuItem onClick={() => window.open(kbDocUrl)}>
            <ListItemIcon>
              <BookmarkIcon color="primary" />
            </ListItemIcon>
            Documentation
          </MenuItem>
        )}
        {status === ConnectionState.RequestAccess && (
          <MenuItem component={LinkBehavior} href={requestEmailHref}>
            <ListItemIcon>
              <AddTaskIcon color="primary" />
            </ListItemIcon>
            Request Access
          </MenuItem>
        )}
        {status !== ConnectionState.RequestAccess && (
          <MenuItem
            component={LinkBehavior}
            href={AppRoutes.connectionEdit.buildRoute(endpointName)}
          >
            <ListItemIcon>
              <EditIcon color="primary" />
            </ListItemIcon>
            Edit Connection
          </MenuItem>
        )}
      </Menu>
    </>
  );

  return (
    <Paper component={Grid} container height="80px" alignItems="center" px={2}>
      <Grid item xs={4} sm={3}>
        <Box display="flex" alignItems="center">
          <Box component="img" src={Endpoint.getLogo(endpointName)} maxWidth="140px" />
        </Box>
      </Grid>
      <Grid item xs={6} sm={2} md={4} display="flex" justifyContent="center" flexShrink={2}>
        {status === ConnectionState.Connected && <SuccessChip label="Connected" />}
        {status === ConnectionState.NotConnected && <WarningChip label="No Access" />}
        {status === ConnectionState.RequestAccess && <Chip label="Not Available" />}
      </Grid>
      <Grid
        item
        xs={2}
        sm={7}
        md={5}
        display="flex"
        justifyContent="flex-end"
        gap={mdMatch ? 2 : 1}
      >
        {smMatch ? ActionButtons : ActionMenu}
      </Grid>
    </Paper>
  );
};

export default ConnectionCard;
