import { BillingMotion } from "api/client.generated";
import BillingMappingAddEditLayout from "components/layout/Sync/BillingMappingAddEditLayout";
import useSourceCustomerAgreement from "hooks/api/useSourceCustomerAgreement";
import useTargetCustomerOptions from "hooks/api/useTargetCustomerOptions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditCustomerMappingViewModel, {
  CreateNewEditMappingVm,
} from "types/edit-customer-mapping-vm";
import SyncRouteParams from "types/sync-route-params";

interface AddMappingRouteParams extends SyncRouteParams {
  srcCustomerId: string;
  srcAgreementId: string;
  billingMotion: BillingMotion;
}

const BillingMappingAddPage = () => {
  const { source, target, program, srcCustomerId, srcAgreementId, billingMotion } = useParams<
    keyof AddMappingRouteParams
  >() as AddMappingRouteParams;

  const [mappingVm, setMappingVm] = useState<EditCustomerMappingViewModel | undefined>(undefined);

  const { customer, isCustomerFetching } = useSourceCustomerAgreement(
    source,
    program,
    srcCustomerId,
    srcAgreementId,
  );

  const { targetCustomerOptions, isTargetCustomerOptionsFetching } = useTargetCustomerOptions(
    source,
    target,
    program,
    billingMotion,
    srcCustomerId,
    srcAgreementId,
  );

  useEffect(() => {
    if (customer) setMappingVm(CreateNewEditMappingVm(customer, billingMotion, program));
  }, [customer, billingMotion, program]);

  return (
    <BillingMappingAddEditLayout
      source={source}
      target={target}
      program={program}
      mapping={mappingVm}
      isMappingLoading={isCustomerFetching}
      targetCustomers={targetCustomerOptions}
      isTargetCustomersLoading={isTargetCustomerOptionsFetching}
    />
  );
};

export default BillingMappingAddPage;
