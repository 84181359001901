import { useQuery } from "@tanstack/react-query";
import { BillingMotion, Customer, ProgramType, Source, Target } from "api/client.generated";
import useApi from "./useApi";

interface UseTargetCustomerOptionsReturn {
  targetCustomerOptions: Customer[] | undefined;
  isTargetCustomerOptionsFetching: boolean;
}

const useTargetCustomerOptions = (
  source: Source,
  target: Target,
  program: ProgramType,
  billingMotion: BillingMotion,
  sourceCustomerId: string,
  sourceAgreementId: string,
): UseTargetCustomerOptionsReturn => {
  const { customerMappingsClient } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: [
      "getTargetCustomerOptions",
      target,
      billingMotion,
      sourceCustomerId,
      sourceAgreementId,
      source,
      program,
    ],
    queryFn: () =>
      customerMappingsClient.getTargetCustomerOptions(
        target,
        billingMotion,
        sourceCustomerId,
        sourceAgreementId,
        source,
        program,
      ),
  });

  return { targetCustomerOptions: data, isTargetCustomerOptionsFetching: isFetching };
};

export default useTargetCustomerOptions;
