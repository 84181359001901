import { Box, FormSelectOption, Paper, Stack } from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PricingAction,
  ProgramType,
  SavePriceRuleRequest,
  Source,
  UpdatePriceRuleRequest,
} from "api/client.generated";
import { ValidationException } from "api/client.generated.extensions";
import ControllerFormSelect from "components/primitives/ControllerFormSelect";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormAutocomplete, { FormAutocompleteOption } from "components/primitives/FormAutocomplete";
import FormDatePicker from "components/primitives/FormDatePicker";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormTextField from "components/primitives/FormTextField";
import { useNotificationContext } from "context/notificationContext";
import { useSamplePricingContext } from "context/samplePricingContext";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import useApi from "hooks/api/useApi";
import usePriceRuleAllowedActions from "hooks/api/usePriceRuleAllowedActions";
import useSourceCustomers from "hooks/api/useSourceCustomers";
import useSourceProducts from "hooks/api/useSourceProducts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import NotificationMessage from "types/notification-message";
import { PriceRuleModel, PriceRuleModelStringConstants } from "types/price-rule-model";
import getProgramTypeDisplayName from "utils/program-type";
import * as yup from "yup";

type RateLabelOptions = "Rate" | "Rate (%)" | "Rate ($)";

interface PriceRuleFormProps {
  priceRule: PriceRuleModel | undefined;
}

const PriceRuleForm = ({ priceRule }: PriceRuleFormProps) => {
  const [formRateLabel, setFormRateLabel] = useState<RateLabelOptions>("Rate");
  const [formRateDisabled, setFormRateDisabled] = useState(true);
  const navigate = useNavigate();
  const {
    setAction: setCtxAction,
    setRate: setCtxRate,
    setIsActionRateValidFn,
  } = useSamplePricingContext();
  const [isSaving, setIsSaving] = useState(false);

  const { priceRulesClient } = useApi();
  const { partner } = useSelectedPartnerContext();
  const { raiseSuccessNotification, raiseErrorNotification } = useNotificationContext();

  const [formErrorMsg, setFormErrorMsg] = useState("");

  // #region Allowed actions: fetching and options
  const { allowedActions, isAllowedActionsFetching } = usePriceRuleAllowedActions();
  const allowedActionsOptions = useMemo<FormSelectOption[]>(
    () =>
      allowedActions?.map(
        (action) =>
          ({
            value: action.id,
            label: action.display,
          }) as FormSelectOption,
      ) ?? [],
    [allowedActions],
  );
  // #endregion

  // #region Form schema
  const formSchema = yup.object().shape({
    source: yup.string().required("Required"),
    program: yup.string().required("Required"),
    customer: yup
      .object()
      .shape({
        id: yup.string().required(), // customerId
        label: yup.string().required(), // customerName
      })
      .required("Required"),
    product: yup
      .object()
      .shape({
        id: yup.string().required(), // productSku
        label: yup.string().required(), // productName
      })
      .required("Required"),
    actionId: yup.string().required("Required").min(36), // string guid
    rate: yup
      .number()
      // this is to prioritize 'Required' vs 'Type error'
      .transform((value, origin) => (origin.toString() === "" ? undefined : value))
      .typeError("Must be a number")
      .moreThan(0, "Must be positive")
      // The rate column in SQL is specified as decimal(18,9): total 18 digits,
      // 9 for decimal places, 9 for main part (18-9). If there is more than 9 decimal
      // places provided, SQL will truncate it. But it throws if left part of decimal
      // is exceeded the limit of 9 digits. So we check it.
      .lessThan(1000000000, "Max limit exceeded")
      .when("actionId", {
        is: (actionId: string) =>
          allowedActions?.some((a) => a.id === actionId && a.pricingAction === PricingAction.At) ||
          !actionId,
        then: (schema) => schema,
        otherwise: (schema) => schema.required("Required"),
      })
      .when("actionId", {
        is: (actionId: string) =>
          allowedActions?.some((a) => a.id === actionId && a.isProfitMarginRule),
        then: (schema) => schema.lessThan(100, "Max margin rate exceeded"),
      }),
    startDate: yup
      .date()
      .nullable()
      .test("test-required", "Required", (value) => Boolean(value)),
    endDate: yup.date().nullable().min(yup.ref("startDate"), "Must be greater than Start Date"),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const {
    handleSubmit,
    control,
    resetField,
    trigger,
    formState: { isDirty },
  } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
    defaultValues: priceRule
      ? {
          source: priceRule.source,
          program: priceRule.program,
          customer: {
            id: priceRule?.sourceCustomerId,
            label: priceRule?.sourceCustomerName,
          },
          product: {
            id: priceRule.productSku,
            label: priceRule.productName,
          },
          actionId: priceRule.priceRuleAllowedAction.id,
          rate: priceRule.getRateValue(),
          startDate: priceRule.startDateUtc,
          endDate: priceRule.endDateUtc,
        }
      : {
          source: PriceRuleModelStringConstants.ALL_SOURCES,
          program: PriceRuleModelStringConstants.ALL_PROGRAMS,
          customer: {
            id: PriceRuleModelStringConstants.ALL_CUSTOMERS,
            label: PriceRuleModelStringConstants.ALL_CUSTOMERS,
          },
          product: {
            id: PriceRuleModelStringConstants.ALL_PRODUCTS,
            label: PriceRuleModelStringConstants.ALL_PRODUCTS,
          },
          actionId: "",
          rate: undefined,
          startDate: null,
          endDate: null,
        },
  });

  const sourceWatch = useWatch({ control, name: "source" });
  const programWatch = useWatch({ control, name: "program" });
  const rateWatch = useWatch({ control, name: "rate" });
  const actionIdWatch = useWatch({ control, name: "actionId" });
  const startDateWatch = useWatch({ control, name: "startDate" });
  // #endregion

  const sourceSelectOptions = useMemo<FormSelectOption[]>(
    () =>
      [
        PriceRuleModelStringConstants.ALL_SOURCES,
        ...Object.values(Source).filter((s) => s !== Source.Unsupported),
      ].map((v) => ({
        value: v,
        label: v,
      })),
    [],
  );

  /* Use getSourcePrograms to get programs for source.
   * Not necessary at the moment because:
   *  1. We support only 1 program
   *  2. API should be able to consume Source as combo of flags
   *     and return common list of programs for all sources.
   */
  const programSelectOptions = useMemo<FormSelectOption[]>(
    () =>
      [
        {
          value: PriceRuleModelStringConstants.ALL_PROGRAMS,
          label: PriceRuleModelStringConstants.ALL_PROGRAMS,
        },
        /* I wanna cry. The 'priceRule' db table has 'program' as string...
         * And there is no program check on applying price rules.
         */
        {
          value: ProgramType.MicrosoftCsp,
          label: getProgramTypeDisplayName(ProgramType.MicrosoftCsp),
        },
      ] as FormSelectOption[],
    [],
  );

  // #region Customers: fetching and options
  const { customers, isCustomersFetching } = useSourceCustomers(
    sourceWatch as Source,
    programWatch as ProgramType,
  );
  const customerSelectOptions = useMemo<FormAutocompleteOption[]>(() => {
    const options: FormAutocompleteOption[] =
      customers?.map((x) => ({ id: x.id, label: x.name }) as FormAutocompleteOption) ?? [];

    options.unshift({
      label: PriceRuleModelStringConstants.ALL_CUSTOMERS,
      id: PriceRuleModelStringConstants.ALL_CUSTOMERS,
    });
    return options;
  }, [customers]);
  // #endregion

  // #region Products: fetching and options
  const { products, isProductsFetching } = useSourceProducts(
    sourceWatch as Source,
    programWatch as ProgramType,
  );

  const productOptions = useMemo<FormAutocompleteOption[]>(() => {
    const options: FormAutocompleteOption[] =
      products?.map((x) => ({ id: x.sku, label: x.name }) as FormAutocompleteOption) ?? [];

    options.unshift({
      id: PriceRuleModelStringConstants.ALL_PRODUCTS,
      label: PriceRuleModelStringConstants.ALL_PRODUCTS,
    });
    return options;
  }, [products]);
  // #endregion

  // #region Setting trigger form validation for SamplePricingForm
  const isRateValid = useCallback<() => Promise<boolean>>(
    () => trigger(["actionId", "rate"]),
    [trigger],
  );

  useEffect(() => {
    setIsActionRateValidFn(() => isRateValid);
  }, [setIsActionRateValidFn, isRateValid]);
  // #endregion

  // #region ActionId and Rate change handlers
  const resetRate = useCallback(() => {
    setCtxRate(undefined);
    resetField("rate", { defaultValue: undefined });
  }, [setCtxRate, resetField]);

  useEffect(() => {
    // this is to have "formState.isDirty=false" when rate is modified
    // and changed back to the empty field
    if (rateWatch?.toString().length === 0) {
      resetRate();
      return;
    }

    setCtxRate(rateWatch);
  }, [rateWatch, setCtxRate, resetRate]);

  useEffect(() => {
    const action = allowedActions?.find((a) => a.id === actionIdWatch);
    if (!action) return;

    // set ctx action id
    setCtxAction(action);

    // update Rate label and value
    if (action.pricingAction === PricingAction.At) {
      setFormRateLabel("Rate");
      setFormRateDisabled(true);
      resetRate();
    } else {
      setFormRateDisabled(false);
      setFormRateLabel(action.isPercentage ? "Rate (%)" : "Rate ($)");
    }
  }, [
    actionIdWatch,
    allowedActions,
    setCtxAction,
    setFormRateLabel,
    setFormRateDisabled,
    resetRate,
  ]);
  // #endregion

  // this is to set up ctx values in the 'edit rule' scenario
  // to make 'test' button available in 'Sample Pricing form'
  useEffect(() => {
    if (priceRule) {
      const action = allowedActions?.find((a) => a.id === priceRule.priceRuleAllowedAction.id);
      setCtxAction(action);
      setCtxRate(priceRule.getRateValue());
    }
  }, [setCtxAction, setCtxRate, allowedActions, priceRule]);

  const formFieldDisabled = useMemo<boolean>(
    () => Boolean(priceRule) || isSaving,
    [isSaving, priceRule],
  );

  const onSave = async (formData: SchemaType): Promise<void> => {
    const action = allowedActions?.find((a) => a.id === formData.actionId);
    if (!action) throw Error("Action not found");

    let rate;
    if (formData.rate) rate = action.isPercentage ? formData.rate / 100 : formData.rate;

    const newRuleRequest: SavePriceRuleRequest = {
      id: undefined,
      endDateUtc: formData.endDate ?? undefined,
      partnerId: partner?.id ?? "",
      priceRuleAllowedAction: action,
      productName:
        formData.product.label === PriceRuleModelStringConstants.ALL_PRODUCTS
          ? undefined
          : formData.product.label,
      productSku:
        formData.product.id === PriceRuleModelStringConstants.ALL_PRODUCTS
          ? undefined
          : formData.product.id,
      program:
        formData.program === PriceRuleModelStringConstants.ALL_PROGRAMS
          ? undefined
          : formData.program,
      rate,
      source:
        formData.source === PriceRuleModelStringConstants.ALL_SOURCES
          ? undefined
          : (formData.source as Source),
      sourceCustomerId:
        formData.customer.id === PriceRuleModelStringConstants.ALL_CUSTOMERS
          ? undefined
          : formData.customer.id,
      sourceCustomerName:
        formData.customer.label === PriceRuleModelStringConstants.ALL_CUSTOMERS
          ? undefined
          : formData.customer.label,
      startDateUtc: formData.startDate!,
    };

    try {
      await priceRulesClient.savePriceRule(newRuleRequest);
      raiseSuccessNotification(NotificationMessage.PRICE_RULE_SAVED);
      navigate(AppRoutes.priceRules.route);
    } catch (e: unknown) {
      const validationError = ValidationException.parse(e);
      if (validationError) {
        setFormErrorMsg(validationError.getFirstErrorMessage());
      } else {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      }
      raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_PRICE_RULE);
    }
  };

  const onUpdate = async (formData: SchemaType): Promise<void> => {
    const apiRule = priceRule!.getApiPriceRule();
    const updateRuleRequest: UpdatePriceRuleRequest = {
      id: apiRule.id,
      endDateUtc: formData.endDate ?? undefined,
      partnerId: partner?.id ?? "",
      priceRuleAllowedAction: apiRule.priceRuleAllowedAction,
      productName: apiRule.productName,
      productSku: apiRule.productSku,
      program: apiRule.program,
      rate: apiRule.rate,
      source: apiRule.source,
      sourceCustomerId: apiRule.sourceCustomerId,
      sourceCustomerName: apiRule.sourceCustomerName,
      startDateUtc: apiRule.startDateUtc,
    };

    try {
      await priceRulesClient.updatePriceRule(updateRuleRequest);
      raiseSuccessNotification(NotificationMessage.PRICE_RULE_UPDATED);
      navigate(AppRoutes.priceRules.route);
    } catch (e: unknown) {
      const validationError = ValidationException.parse(e);
      if (validationError) {
        setFormErrorMsg(validationError.getFirstErrorMessage());
      } else {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      }
      raiseErrorNotification(NotificationMessage.FAILED_TO_UPDATE_PRICE_RULE);
    }
  };

  const onSaveClick = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);

    if (priceRule) {
      onUpdate(formData);
    } else {
      onSave(formData);
    }

    setIsSaving(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSaveClick)}>
      <Paper
        component={Stack}
        spacing={4}
        sx={{
          width: "430px",
          p: 2,
        }}
      >
        <ControllerFormSelect
          bindSchemaFieldName="source"
          control={control}
          label="Source"
          testId="Source"
          options={sourceSelectOptions}
          disabled={formFieldDisabled}
        />
        <ControllerFormSelect
          bindSchemaFieldName="program"
          control={control}
          label="Program"
          testId="Program"
          options={programSelectOptions}
          disabled={formFieldDisabled}
        />
        <FormAutocomplete
          bindSchemaFieldName="customer"
          control={control}
          label="Customer"
          testId="Customer"
          disabled={formFieldDisabled}
          options={customerSelectOptions}
          isLoading={isCustomersFetching}
        />
        <FormAutocomplete
          bindSchemaFieldName="product"
          control={control}
          label="Product"
          testId="Product"
          disabled={formFieldDisabled}
          options={productOptions}
          isLoading={isProductsFetching}
        />
        <Box display="flex" gap={2}>
          <ControllerFormSelect
            control={control}
            bindSchemaFieldName="actionId"
            label="Action"
            testId="Action"
            disabled={formFieldDisabled}
            sxRoot={{ flex: 1 }}
            options={allowedActionsOptions}
            isLoading={isAllowedActionsFetching}
            required
          />
          <Box maxWidth="125px">
            <FormTextField
              control={control}
              bindSchemaFieldName="rate"
              label={formRateLabel}
              testId="Rate"
              disabled={formRateDisabled || formFieldDisabled}
              required={!formRateDisabled}
            />
          </Box>
        </Box>
        <Box display="flex" gap={2}>
          <Box width="50%">
            <FormDatePicker
              id="start-date"
              control={control}
              bindSchemaFieldName="startDate"
              label="Start Date UTC"
              testId="Start Date UTC"
              disabled={formFieldDisabled}
              required
            />
          </Box>
          <Box width="50%">
            <FormDatePicker
              id="end-date"
              control={control}
              bindSchemaFieldName="endDate"
              label="End Date UTC"
              testId="End Date UTC"
              disabled={isSaving}
              minDate={startDateWatch ?? undefined}
            />
          </Box>
        </Box>
        <FormErrorMessage message={formErrorMsg} />
        <FormActionButtons
          cancelRoute={AppRoutes.priceRules.route}
          isSaveDisable={
            isAllowedActionsFetching || isCustomersFetching || isProductsFetching || !isDirty
          }
          isSaveLoading={isSaving}
        />
      </Paper>
      <DevTool control={control} />
    </Box>
  );
};

export default PriceRuleForm;
