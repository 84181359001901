import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Customer,
  SaveCustomerMappingCommand,
  SetLastProcessedInvoiceDateCommand,
} from "api/client.generated";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormAutocomplete, { FormAutocompleteOption } from "components/primitives/FormAutocomplete";
import FormDatePicker from "components/primitives/FormDatePicker";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormSwitch from "components/primitives/FormSwitch";
import { useConfirmationDialogContext } from "context/confirmationDialogContext";
import { useNotificationContext } from "context/notificationContext";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import useApi from "hooks/api/useApi";
import useToggle from "hooks/useToggle";
import { ExpandLessIcon, ExpandMoreIcon } from "images/MuiIcons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import EditCustomerMappingViewModel from "types/edit-customer-mapping-vm";
import NotificationMessage from "types/notification-message";
import SyncRouteParams from "types/sync-route-params";
import {
  getTargetAgreementLabel,
  getTargetCustomerLabel,
} from "utils/target-customer-agreement-labels";
import * as yup from "yup";

interface BillingMappingFormProps {
  mapping: EditCustomerMappingViewModel | undefined;
  isMappingLoading?: boolean;
  targetCustomers: Customer[] | undefined;
  isTargetCustomersLoading?: boolean;
}

const BillingMappingForm = ({
  mapping,
  isMappingLoading = false,
  targetCustomers,
  isTargetCustomersLoading = false,
}: BillingMappingFormProps) => {
  const { source, target, program } = useParams<keyof SyncRouteParams>() as SyncRouteParams;
  const [isAdvancedSettingsOpen, toggleAdvancedSettingOpen] = useToggle(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const { raiseConfirmationDialog } = useConfirmationDialogContext();
  const [isSaving, setIsSaving] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");

  const { customerMappingsClient } = useApi();
  const { partner } = useSelectedPartnerContext();
  const { raiseSuccessNotification, raiseErrorNotification } = useNotificationContext();

  const formSchema = yup.object().shape({
    syncEnabled: yup.boolean().required(),
    // repeats `options` prop type for <FormAutocomplete> which is FormAutocompleteOption
    targetCustomer: yup
      .object()
      .shape({
        id: yup.string().required(),
        label: yup.string(),
      })
      .nullable()
      // can't use build-in 'required()' because it overrides 'nullable()' which adds 'null' type
      .test("test-required", "Required", (value) => Boolean(value)),
    targetAgreement: yup
      .object()
      .shape({
        id: yup.string().required(),
        label: yup.string(),
      })
      .nullable()
      .test("test-required", "Required", (value) => Boolean(value)),
    lastProcessedInvoiceDate: yup.date().notRequired(),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const {
    control,
    resetField,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      syncEnabled: false,
      targetCustomer: null,
      targetAgreement: null,
      lastProcessedInvoiceDate: null,
    },
  });

  useEffect(() => {
    if (!mapping || !targetCustomers) return;

    reset({
      syncEnabled: mapping.isSyncing,
      targetCustomer: !mapping.targetCustomer
        ? null
        : {
            id: mapping.targetCustomer.id,
            label: mapping.targetCustomer.name,
          },
      targetAgreement: !mapping.targetCustomerAgreement
        ? null
        : {
            id: mapping.targetCustomerAgreement.id,
            label: mapping.targetCustomerAgreement.name,
          },
      lastProcessedInvoiceDate: null,
    });
  }, [reset, mapping, targetCustomers]);

  const targetCustomerIdWatch = useWatch({ control, name: "targetCustomer.id" });
  const targetAgreementIdWatch = useWatch({ control, name: "targetAgreement.id" });

  const [isTargetAgreementDisabled, setIsTargetAgreementDisabled] = useState<boolean>(
    !mapping?.targetCustomer?.id,
  );

  const targetCustomerOptions = useMemo<FormAutocompleteOption[]>(
    () =>
      targetCustomers
        ? targetCustomers.map((x) => ({ id: x.id, label: x.name }) as FormAutocompleteOption)
        : [],
    [targetCustomers],
  );

  const findTargetAgreementOptions = useCallback(
    (targetCustomerId: string | undefined): FormAutocompleteOption[] => {
      const customer = targetCustomers?.find((x) => x.id === targetCustomerId);
      return (
        customer?.agreements?.map((x) => ({ id: x.id, label: x.name }) as FormAutocompleteOption) ??
        []
      );
    },
    [targetCustomers],
  );

  const [targetAgreementOptions, setTargetAgreementOptions] = useState<FormAutocompleteOption[]>(
    [],
  );

  useEffect(() => {
    const newContractOptions = findTargetAgreementOptions(targetCustomerIdWatch);
    setTargetAgreementOptions(newContractOptions);

    if (!newContractOptions.some((x) => x.id === targetAgreementIdWatch))
      resetField("targetAgreement", { defaultValue: null });

    setIsTargetAgreementDisabled(!targetCustomerIdWatch);
  }, [
    targetCustomerIdWatch,
    targetAgreementIdWatch,
    findTargetAgreementOptions,
    setTargetAgreementOptions,
    resetField,
  ]);

  const targetCustomerLabel = useMemo<string>(() => getTargetCustomerLabel(target), [target]);

  const targetAgreementLabel = useMemo<string>(() => getTargetAgreementLabel(target), [target]);

  const billingSyncOverviewRoute = useMemo<string>(
    () => AppRoutes.billingSync.buildRoute(source, target, program),
    [source, target, program],
  );

  const onSave = async (formData: SchemaType): Promise<void> => {
    if (!mapping) return;

    if (!formData.targetCustomer || !formData.targetAgreement) return;

    setIsSaving(true);

    let mappingId = mapping.id;
    let submitSucceeded = true;

    // save mapping changes if there is any
    if (dirtyFields.syncEnabled || dirtyFields.targetCustomer || dirtyFields.targetAgreement) {
      const saveMappingCommand: SaveCustomerMappingCommand = {
        billingMotion: mapping.billingMotion,
        id: mapping.id,
        isSyncing: formData.syncEnabled,
        partnerId: partner?.id ?? "",
        pricingSyncStrategy: mapping.pricingSyncStrategy,
        programType: program,
        source,
        target,
        sourceCustomerId: mapping.sourceCustomer.id,
        sourceAgreementId: mapping.sourceCustomerAgreement.id,
        targetCustomerId: formData.targetCustomer.id,
        targetAgreementId: formData.targetAgreement.id,
        startSyncDate: undefined,
      };

      try {
        const response = await customerMappingsClient.saveCustomerMapping(
          source,
          target,
          program,
          saveMappingCommand,
        );
        mappingId = response.id;
        raiseSuccessNotification(NotificationMessage.MAPPING_SAVED);
      } catch (e: unknown) {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
        raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_MAPPING);
        submitSucceeded = false;
      }
    }

    // update lastProcessedInvoiceDate if changed
    if (dirtyFields.lastProcessedInvoiceDate && formData.lastProcessedInvoiceDate && mappingId) {
      const setCommand: SetLastProcessedInvoiceDateCommand = {
        customerMappingId: mappingId,
        lastProcessedInvoiceDate: formData.lastProcessedInvoiceDate,
        partnerId: partner?.id ?? "",
      };
      try {
        await customerMappingsClient.updateLastProcessedInvoiceDate(
          source,
          target,
          program,
          setCommand,
        );
        raiseSuccessNotification(NotificationMessage.LAST_INV_DATE_UPDATED);
      } catch (e: unknown) {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
        raiseErrorNotification(NotificationMessage.FAILED_TO_UPDATE_LAST_INV_DATE);
        submitSucceeded = false;
      }
    }
    setIsSaving(false);

    if (submitSucceeded) navigate(billingSyncOverviewRoute);
  };

  const onDeleteMapping = async (): Promise<void> => {
    if (!mapping?.id) return;

    const isConfirmed = await raiseConfirmationDialog(
      "Delete mapping",
      "Please confirm mapping deletion",
    );
    if (!isConfirmed) return;

    setIsSaving(true);

    try {
      await customerMappingsClient.deleteCustomerMapping(mapping.id, source, target, program);
      raiseSuccessNotification(NotificationMessage.MAPPING_DELETED);
      navigate(billingSyncOverviewRoute);
    } catch (e: unknown) {
      setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      raiseErrorNotification(NotificationMessage.FAILED_TO_DELETE_MAPPING);
    }
    setIsSaving(false);
  };

  const onResetMapping = async (): Promise<void> => {
    if (!mapping?.id) return;

    const isConfirmed = await raiseConfirmationDialog(
      "Reset sync status",
      "Please confirm sync status resetting",
    );
    if (!isConfirmed) return;

    setIsSaving(true);

    try {
      await customerMappingsClient.resetCustomerMapping(mapping.id, source, target, program);
      raiseSuccessNotification(NotificationMessage.SYNC_STATUS_WAS_RESET);
      navigate(billingSyncOverviewRoute);
    } catch (e: unknown) {
      setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      raiseErrorNotification(NotificationMessage.FAILED_TO_RESET_SYNC_STATUS);
    }
    setIsSaving(false);
  };

  const EnableSettingSlice = (
    <Box display="flex" alignItems="center">
      {isMappingLoading ? (
        <Skeleton variant="rounded" height={32} width={150} />
      ) : (
        <Typography variant="h6">{mapping?.sourceCustomer?.name}</Typography>
      )}
      <FormSwitch
        control={control}
        bindSchemaFieldName="syncEnabled"
        sxRoot={{ ml: "auto" }}
        disabled={isSaving}
        testId="syncEnabled"
        // lock 'Enable' switch because form reset happens when all api calls finished
        isLoading={isMappingLoading || isTargetCustomersLoading}
      />
    </Box>
  );

  const SourceSettingSlice = (
    <Box display="flex" flex="wrap" gap={5}>
      {isMappingLoading ? (
        <Skeleton variant="rounded" height={56} sx={{ flex: 1 }} />
      ) : (
        <TextField
          label="Tenant"
          testId="Tenant"
          value={mapping?.sourceCustomerAgreement.name ?? ""}
          InputProps={{ readOnly: true }}
          sx={{ flex: 1 }}
          disabled={isSaving}
        />
      )}
      {isMappingLoading ? (
        <Skeleton variant="rounded" height={56} sx={{ flex: 1 }} />
      ) : (
        <TextField
          label="Billing Motion"
          testId="Billing Motion"
          value={mapping?.billingMotion ?? ""}
          InputProps={{ readOnly: true }}
          sx={{ flex: 1 }}
          disabled={isSaving}
        />
      )}
    </Box>
  );

  const TargetSettingSlice = (
    <Box display="flex" flex="wrap" gap={5}>
      <FormAutocomplete
        control={control}
        bindSchemaFieldName="targetCustomer"
        label={targetCustomerLabel}
        testId={targetCustomerLabel}
        options={targetCustomerOptions}
        sxRoot={{ flex: 1 }}
        disabled={isSaving}
        isLoading={isTargetCustomersLoading || isMappingLoading}
        clearable
        required
      />
      <FormAutocomplete
        control={control}
        bindSchemaFieldName="targetAgreement"
        label={targetAgreementLabel}
        testId={targetAgreementLabel}
        options={targetAgreementOptions}
        sxRoot={{ flex: 1 }}
        disabled={isTargetAgreementDisabled || isSaving}
        isLoading={isTargetCustomersLoading || isMappingLoading}
        clearable
        required
      />
    </Box>
  );

  const AdvancedOptionsSlice = (
    <List>
      <ListItemButton
        disableRipple
        disableGutters
        onClick={toggleAdvancedSettingOpen}
        sx={{ "&:hover": { bgcolor: theme.palette.action.hover } }}
        disabled={isMappingLoading || isTargetCustomersLoading}
      >
        <ListItemText
          primaryTypographyProps={{ variant: "subtitle1" }}
          primary="Advanced Options"
        />
        {isAdvancedSettingsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={isAdvancedSettingsOpen}>
        <Box display="flex" alignItems="center" gap={4} sx={{ py: 2 }}>
          <FormDatePicker
            id="last-processed-invoice-date"
            control={control}
            bindSchemaFieldName="lastProcessedInvoiceDate"
            label="Last Processed Invoice Date"
            sxRoot={{ flex: 1 }}
            disabled={isSaving}
          />
          <Box flex={1}>
            <Box display="flex" justifyContent="space-around">
              <Button
                color="error"
                variant="outlined"
                onClick={onResetMapping}
                disabled={!mapping?.id || isSaving}
                testId="Reset Sync Status"
              >
                Reset Sync Status
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={onDeleteMapping}
                disabled={!mapping?.id || isSaving}
                testId="Delete Mapping"
              >
                Delete Mapping
              </Button>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </List>
  );

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2}>
        <Stack spacing={4}>
          {EnableSettingSlice}
          {SourceSettingSlice}
          {TargetSettingSlice}
          <Divider />

          <Box sx={{ "&&": { mt: 0 } }}>{AdvancedOptionsSlice}</Box>
          <Divider sx={{ "&&": { mt: 0 } }} />

          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons
            cancelRoute={billingSyncOverviewRoute}
            isSaveLoading={isSaving}
            isSaveDisable={isMappingLoading || isTargetCustomersLoading}
          />
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default BillingMappingForm;
