import { DatePicker, SxProps, Theme, useTheme } from "@crayon/design-system-react";
import { DateTime } from "luxon";
import { useState } from "react";
import { Control, Controller, FieldValues, Path, PathValue, useWatch } from "react-hook-form";

interface FormDatePickerProps<TField extends FieldValues> {
  bindSchemaFieldName: Path<TField>;
  control: Control<TField>;
  disabled?: boolean;
  id: string;
  label: string;
  sxRoot?: SxProps<Theme>;
  testId?: string;
  minDate?: Date;
  required?: boolean;
}

const FormDatePicker = <TField extends FieldValues>({
  bindSchemaFieldName,
  control,
  id,
  label,
  disabled = false,
  sxRoot = undefined,
  testId = undefined,
  minDate = undefined,
  required = undefined,
}: FormDatePickerProps<TField>) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const initFieldValue = useWatch({ control, name: bindSchemaFieldName });
  const fieldValueToDateTime = (fieldValue: PathValue<TField, Path<TField>> | null) =>
    fieldValue ? DateTime.fromJSDate(fieldValue) : null;

  const [prevDate, setPrevDate] = useState<DateTime | null>(fieldValueToDateTime(initFieldValue));

  return (
    <Controller
      control={control}
      name={bindSchemaFieldName}
      render={({ field, fieldState }) => {
        const onDateChange = (e: DateTime | null) =>
          field.onChange(e ? DateTime.utc(e.year, e.month, e.day).toJSDate() : null);

        const saveToPrevValueAndClose = (fieldValue: PathValue<TField, Path<TField>> | null) => {
          setPrevDate(fieldValueToDateTime(fieldValue));
          setOpen(false);
        };

        return (
          <DatePicker
            id={id}
            testId={testId}
            label={label}
            disabled={disabled}
            value={field.value ? DateTime.fromJSDate(field.value) : null}
            onChange={(e: DateTime | null) => onDateChange(e)}
            inputRef={field.ref}
            sx={{
              ...sxRoot,
              "& input": {
                color: theme.palette.secondary.main,
              },
            }}
            slotProps={{
              field: { clearable: true },
              textField: {
                error: Boolean(fieldState.error),
                helperText: fieldState.error?.message,
              },
              // this 'actionBar' block is to fix default mobile view behavior
              // 1. To display 'Clear' button
              // 2. Default 'onCancel' handler doesn't revert prev selected value. So for that:
              //    a. Using 'prevDate' state.
              //    b. Override 'onCancel' handler. And Dialog stopped closing automatically...
              //      b.x: Introducing 'open' state to control opening and closing
              //           Overriding 'onOpen', 'onAccept', 'onClear' and 'onClose'
              //           to update 'prevDate' state to make 'onCancel' work correctly
              actionBar: ({ wrapperVariant }) => {
                if (wrapperVariant === "desktop") return {};

                return {
                  onCancel() {
                    onDateChange(prevDate);
                    setOpen(false);
                  },
                  onAccept() {
                    saveToPrevValueAndClose(field.value);
                  },
                  onClear() {
                    onDateChange(null);
                    saveToPrevValueAndClose(null);
                  },
                  actions: field.value ? ["clear", "cancel", "accept"] : ["cancel", "accept"],
                };
              },
            }}
            open={open}
            onOpen={() => setOpen(true)}
            // called on clickaway
            onClose={() => saveToPrevValueAndClose(field.value)}
            minDate={minDate ? DateTime.fromJSDate(minDate) : undefined}
            required={required}
          />
        );
      }}
    />
  );
};

export default FormDatePicker;
