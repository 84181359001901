import {
  Box,
  Divider,
  FormSelect,
  FormSelectChangeEvent,
  FormSelectOption,
  Paper,
  Stack,
  TextField,
  useTheme,
} from "@crayon/design-system-react";
import { BillingMotion, CustomerMappingModel } from "api/client.generated";
import BillingMappingTable, {
  BillingMappingTableRef,
} from "components/hoc/Sync/Billing/BillingMappingTable";
import { useSyncOverviewContext } from "context/syncOverviewContext";
import useCustomerMappings from "hooks/api/useCustomerMappings";
import useSyncHistory from "hooks/api/useSyncHistory";
import useDebounce from "hooks/useDebounce";
import {
  CheckCircleIcon,
  CheckCircleOutlineIcon,
  ErrorCircleIcon,
  WarningCirceIcon,
} from "images/MuiIcons";
import { useEffect, useMemo, useRef, useState } from "react";
import CustomerMappingViewModel from "types/customer-mapping-vm";

export interface BillingMapping {
  isActive: boolean;
  customer: string;
  tenant: string;
  billingMotion: string;
  targetCompany: string;
  targetContract: string;
  invoice: string;
}

const BillingMappingOverview = () => {
  const theme = useTheme();
  const [billingMotionFilter, setBillingMotionFilter] = useState("All");
  const [searchFilter, setSearchFilter] = useState("");
  const searchFilterDebounced = useDebounce(searchFilter);

  const { source, target, program } = useSyncOverviewContext();
  const { customerMappings, isCustomerMappingsFetching } = useCustomerMappings(
    source,
    target,
    program,
  );

  const [filteredMappings, setFilteredMappings] = useState<CustomerMappingViewModel[]>([]);

  const tableRef = useRef<BillingMappingTableRef>(null);

  const { syncHistory } = useSyncHistory(source, target, program, "Billing", 0, 1);

  useEffect(() => {
    const filterMappings = (mapping: CustomerMappingModel): boolean => {
      if (billingMotionFilter !== "All") {
        if (mapping.billingMotion !== billingMotionFilter) return false;
      }

      if (searchFilterDebounced !== "") {
        const filter = searchFilterDebounced.toLocaleLowerCase();
        const match =
          mapping.sourceCustomer?.name?.toLocaleLowerCase().includes(filter) ||
          mapping.sourceCustomer?.agreement?.name?.toLocaleLowerCase().includes(filter) ||
          mapping.targetCustomer?.name?.toLocaleLowerCase().includes(filter) ||
          mapping.targetCustomer?.agreement?.name?.toLocaleLowerCase().includes(filter) ||
          mapping.lastProcessedInvoice?.toLocaleLowerCase().includes(filter);
        return Boolean(match);
      }

      return true;
    };

    const getStatusIcon = (mapping: CustomerMappingModel): React.ReactNode => {
      if (!mapping.isSyncing) return undefined;
      if (mapping.isLastSyncError) return <ErrorCircleIcon color="error" />;
      if (mapping.warnings?.length) return <WarningCirceIcon color="warning" />;

      if (
        Boolean(mapping.lastProcessedInvoice) &&
        syncHistory?.at(0)?.invoices.includes(mapping.lastProcessedInvoice!)
      )
        return <CheckCircleIcon color="success" />;

      return <CheckCircleOutlineIcon sx={{ color: "grey" }} />;
    };

    /* We need to reset selected page because it might be out of range
     * when filteredMappings is changed. It might be because:
     *  1. Filter is changed: search field or TargetType select
     *  2. When switching to another {source->target} page (table component
     *     stays in the DOM with saving its state)
     *
     * The combo of "forwardRef" and "useImperativeHandle" hook is used
     * to access child props from parent. See BillingMappingTable component
     *
     * The same is applied to ProductMappingOverview and ProductMappingTable
     */
    tableRef.current?.resetSelectedPage();

    const filtered = customerMappings?.filter(filterMappings) ?? [];
    setFilteredMappings(
      filtered.map((m) => ({ ...m, statusIcon: getStatusIcon(m) }) as CustomerMappingViewModel),
    );
  }, [customerMappings, billingMotionFilter, searchFilterDebounced, syncHistory]);

  const billingMotionOptions = useMemo<FormSelectOption[]>(
    () => [
      { value: "All", label: "All" },
      { value: BillingMotion.SeatBased, label: BillingMotion.SeatBased },
      { value: BillingMotion.AzurePlan, label: BillingMotion.AzurePlan },
      {
        value: BillingMotion.SeatBasedAnnual,
        label: BillingMotion.SeatBasedAnnual,
      },
    ],
    [],
  );

  const FiltersSlice = (
    <Box display="flex" gap={3} flexWrap="wrap">
      <TextField
        label="Search"
        testId="Search"
        onChange={(event) => setSearchFilter(event.target.value)}
        sx={{
          flex: 1,
          input: {
            color: theme.palette.secondary.main,
            "&::placeholder": { color: "grey" },
          },
        }}
      />
      <FormSelect
        id="billing-motion-lbl-id"
        label="Billing Motion"
        testId="Billing Motion"
        onChange={(event: FormSelectChangeEvent) => setBillingMotionFilter(event.target.value)}
        options={billingMotionOptions}
        sx={{ flex: 1, ".MuiSelect-select": { color: theme.palette.secondary.main } }}
        value={billingMotionFilter}
      />
    </Box>
  );

  return (
    <Stack component={Paper} spacing={3}>
      <Box pt={3} px={3}>
        {FiltersSlice}
      </Box>
      <Divider />
      <Box sx={{ "&&": { mt: 0 } }}>
        <BillingMappingTable
          ref={tableRef}
          mappings={filteredMappings}
          isLoading={isCustomerMappingsFetching}
        />
      </Box>
    </Stack>
  );
};

export default BillingMappingOverview;
