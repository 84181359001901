import {
  Box,
  IconButton,
  LinkBehavior,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@crayon/design-system-react";
import { BillingMotion } from "api/client.generated";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodySkeleton from "components/primitives/TableBodySkeleton";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import { useSyncOverviewContext } from "context/syncOverviewContext";
import { EditIcon, SyncIcon, SyncLockIcon } from "images/MuiIcons";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import AppRoutes from "routes/app-routes";
import CustomerMappingViewModel from "types/customer-mapping-vm";
import DefaultPaginationOptions from "types/defaultPaginationOptions";
import { parseEnumFlags } from "utils/enum-flags";
import {
  getTargetAgreementLabel,
  getTargetCustomerLabel,
} from "utils/target-customer-agreement-labels";

export interface BillingMappingTableRef {
  resetSelectedPage: () => void;
}

interface BillingMappingTableProps {
  mappings: CustomerMappingViewModel[];
  isLoading: boolean;
}

const BillingMappingTable = forwardRef<BillingMappingTableRef, BillingMappingTableProps>(
  (props, ref) => {
    const { mappings, isLoading } = props;
    const [page, setPage] = useState(0);
    const rowsPerPageOptions = useMemo<number[]>(() => DefaultPaginationOptions, []);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const { source, target, program } = useSyncOverviewContext();

    const { partnerConfig } = usePartnerConfigContext();
    const activeBillingMotions = useMemo<BillingMotion[]>(() => {
      const activeBmFlags = partnerConfig?.configuredPrograms
        ?.find((p) => p.source === source && p.target === target)
        ?.programs?.find((p) => p.type === program)?.activeBillingMotions;

      return activeBmFlags ? parseEnumFlags(BillingMotion, activeBmFlags) : [];
    }, [partnerConfig, source, target, program]);

    useImperativeHandle(ref, () => ({
      resetSelectedPage: () => setPage(0),
    }));

    const columnOptions = useMemo<string[]>(() => {
      const columns = [
        "",
        `${source} Customer`,
        "Tenant",
        "Billing Motion",
        getTargetCustomerLabel(target),
        getTargetAgreementLabel(target),
        "Invoice",
        "Enabled",
      ];

      // delete 1st column (for invoice sync status icon) on loading data
      // to get rid if small ugly loading skeleton for this column
      if (isLoading) columns.shift();

      return columns;
    }, [source, target, isLoading]);

    const onRowsPerPageChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const getEditMappingRoute = (mapping: CustomerMappingViewModel): string =>
      !mapping.id
        ? AppRoutes.billingSyncAddMapping.buildRoute(
            source,
            target,
            program,
            mapping.sourceCustomer.id ?? "",
            mapping.sourceCustomer.agreement?.id ?? "",
            mapping.billingMotion,
          )
        : AppRoutes.billingSyncEditMapping.buildRoute(source, target, program, mapping.id);

    const renderHeaderCell = (data: string, i: number) => (
      <TableHeaderCell
        key={data + String(i)}
        columnName={data}
        colSpan={i === columnOptions.length - 1 ? 2 : 1}
      />
    );

    const renderEnabledIcon = (mapping: CustomerMappingViewModel) => (
      <TableCell>
        {mapping.id &&
          (activeBillingMotions.includes(mapping.billingMotion) ? (
            <SyncIcon color={mapping.isSyncing ? "success" : "disabled"} />
          ) : (
            <Tooltip title="Billing motion not enabled for the program">
              <SyncLockIcon color="warning" />
            </Tooltip>
          ))}
      </TableCell>
    );

    const renderEditButton = (mapping: CustomerMappingViewModel) => (
      <TableCell align="right">
        <IconButton color="primary" component={LinkBehavior} href={getEditMappingRoute(mapping)}>
          <EditIcon />
        </IconButton>
      </TableCell>
    );

    const DataTableBody = (
      <TableBody>
        {mappings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((mapping) => (
          <TableRow
            key={
              mapping.id +
              (mapping.sourceCustomer?.id ?? "") +
              (mapping.sourceCustomer?.agreement?.id ?? "") +
              mapping.billingMotion
            }
          >
            <TableBodyCell icon={mapping.statusIcon} />
            <TableBodyCell label={mapping.sourceCustomer?.name} />
            <TableBodyCell label={mapping.sourceCustomer?.agreement?.name} />
            <TableBodyCell label={mapping.billingMotion} />
            <TableBodyCell label={mapping.targetCustomer?.name} />
            <TableBodyCell label={mapping.targetCustomer?.agreement?.name} />
            <TableBodyCell label={mapping.lastProcessedInvoice} />
            {renderEnabledIcon(mapping)}
            {renderEditButton(mapping)}
          </TableRow>
        ))}
      </TableBody>
    );

    return (
      <TableContainer>
        <Table testId="Billing Mapping">
          <TableHead>
            <TableRow>{columnOptions.map(renderHeaderCell)}</TableRow>
          </TableHead>
          {isLoading ? (
            <TableBodySkeleton colsCount={columnOptions.length} rowsCount={3} />
          ) : (
            DataTableBody
          )}
        </Table>
        <TablePagination
          component={Box}
          count={mappings.length}
          page={page}
          onPageChange={(_: React.MouseEvent<HTMLButtonElement>, newPage: number) =>
            setPage(newPage)
          }
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          sx={{ my: 1 }}
        />
      </TableContainer>
    );
  },
);

BillingMappingTable.displayName = "BillingMappingTable";

export default BillingMappingTable;
